import React from 'react';

import cn from 'classnames';
import Image from 'next/image';

import { THEME } from '@constants/base';
import p from '@utils/Transfrom';

// import { inject, observer } from 'mobx-react';
import styles from './index.module.css'

const OauthLogin = (props) => {
  const { btnText, src, theme } = props;
  return (
    <div {...props} className={styles.loginBottom}>
      <Image
        alt={'kikitrade'}
        src={src}
        width={p(90)}
        height={p(90)}
      />
      <div className={cn(styles.lable, 'DINPro', {
        [styles.darkLabel]: theme === THEME.DARK
      })}>
        {btnText}
      </div>
    </div>
  );
};

export const MemoOauthLogin = React.memo(OauthLogin)
