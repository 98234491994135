import React, { useState } from 'react';

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import { THEME } from '@constants/base';

import RadioButtonStyles from './radio.module.css';

export type RadioButtonProps = {
  isRadioChecked: boolean,
  radioText: string,
  linkUrl: string,
  linkContent: string,
  linkAfterContent?: string,
  target?: '_self' | '_blank' | '_parent' | '_top',
  onChange: () => void,
  theme?: Theme
};

export default function RadioButton (props: RadioButtonProps) {
  const {
    target = '_self',
    isRadioChecked,
    radioText,
    linkUrl,
    linkContent,
    linkAfterContent,
    onChange,
    theme
  } = props;
  const [checked, setChecked] = useState(isRadioChecked);
  const statusChange = () => {
    setChecked(!checked);
    onChange?.();
  };

  return (
    <div className={RadioButtonStyles.radioContent} onClick={statusChange}>
      <div className={RadioButtonStyles.radioImage}>
        <Image
          alt="kiki"
          src={
            checked
              ? require('../../images/radioSelected.webp')
              : require('../../images/radio.webp')
          }
          layout="responsive"
        />
      </div>
      <div className={classNames(RadioButtonStyles.radioText, {
        [RadioButtonStyles.dark_radioText]: theme === THEME.DARK
      })}>
        {radioText}
        {linkUrl && (
          <Link href={linkUrl}>
            <a
              className={classNames(RadioButtonStyles.radioLink, {
                [RadioButtonStyles.dark_radioLink]: theme === THEME.DARK
              })}
              target={target}
              onClick={(e) => e.stopPropagation()}
            >
              《
              <span className={classNames(RadioButtonStyles.radioInnerText, 'DINPro', {
                [RadioButtonStyles.dark_radioInnerText]: theme === THEME.DARK
              })}>
                {linkContent}
              </span>
              》
            </a>
          </Link>
        )}
        {linkAfterContent}
      </div>
    </div>
  );
}
